import * as React from 'react';
import {
  FormControl,
  Modal,
  Box,
  Button,
  TextField,
  Typography,
  InputLabel,
  MenuItem,
} from '@mui/material/';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { useAppContext } from '../../lib/contextLib';
import './createValuetree.css';
import { ValuetreeMeta } from '../types';

interface CreateValuetreeProps {
  valuetreeName: string;
  description: string;
  openCreateValuetree: boolean;
  handleChangeName: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeDescription: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleCloseCreateValuetreeDialog: (
    event: React.MouseEvent<HTMLButtonElement>
  ) => void;
  handleSubmitNewValuetree: (event: React.MouseEvent) => void;
}

const CreateValuetree = (props: CreateValuetreeProps) => {
  const {
    valuetreeName,
    description,
    openCreateValuetree,
    handleChangeName,
    handleChangeDescription,
    handleCloseCreateValuetreeDialog,
    handleSubmitNewValuetree,
  } = props;
  const {
    selectedProjectValuetrees,
    copyFromValueTreeId,
    setCopyFromValueTreeId,
  } = useAppContext();

  const handleSelectCopyFromId = (event: SelectChangeEvent) => {
    setCopyFromValueTreeId(event.target.value);
  };

  return (
    <Box>
      <Modal
        hideBackdrop
        open={openCreateValuetree}
        onClose={handleCloseCreateValuetreeDialog}
        aria-labelledby='modal-title'
        aria-describedby='modal-description'
      >
        <Box
          className='create-new-valuetree-modal'
          sx={{ bgcolor: 'background.paper' }}
        >
          <Typography
            id='modal-title'
            variant='h6'
            component='h2'
            sx={{ textAlign: 'center' }}
          >
            Add tree
          </Typography>
          <Box sx={{ p: 0.8 }}>
            <TextField
              label='Name'
              variant='outlined'
              id='name'
              value={valuetreeName}
              size='small'
              sx={{ width: '100%' }}
              autoComplete='off'
              onChange={handleChangeName}
            />
          </Box>
          <Box sx={{ p: 0.8 }}>
            <TextField
              label='Description'
              variant='outlined'
              id='description'
              value={description}
              size='small'
              sx={{ width: '100%' }}
              autoComplete='off'
              onChange={handleChangeDescription}
            />
          </Box>
          <FormControl sx={{ width: '100%', marginTop: '3%' }}>
            <Box sx={{ p: 0.8 }}>
              <Box>
                <InputLabel id='copy-from-label'>Copy from</InputLabel>
                <Select
                  size='small'
                  sx={{ width: '100%' }}
                  labelId='copy-from-label'
                  label='Copy from'
                  value={copyFromValueTreeId}
                  onChange={handleSelectCopyFromId}
                >
                  <MenuItem value=''>Copy from</MenuItem>
                  {selectedProjectValuetrees.map((valuetree: ValuetreeMeta) => {
                    return (
                      <MenuItem key={valuetree.id} value={valuetree.id}>
                        {valuetree.valueTreeName}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Box>
            </Box>
          </FormControl>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '4%',
              width: '100%',
              margin: 'auto',
              justifyContent: 'center',
              padding: '20px 0',
            }}
          >
            <Button
              onClick={handleSubmitNewValuetree}
              type='submit'
              variant='outlined'
              disabled={!valuetreeName}
              sx={{ width: '30%', padding: '10px 0px' }}
            >
              Add valuetree
            </Button>
            <Button
              onClick={handleCloseCreateValuetreeDialog}
              variant='outlined'
              color='warning'
              sx={{ width: '30%', padding: '10px 0px' }}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default CreateValuetree;
