import React, { useEffect, useState } from 'react';
import { API, Auth } from 'aws-amplify';
import { Node, ValuetreeObject, UpdatedNode } from '../types';
import { useParams } from 'react-router-dom';
import { useAppContext } from '../../lib/contextLib';
import { styled } from '@mui/material/styles';
import {
  Box,
  TableCell,
  TableRow,
  Button,
  TextField,
  TextareaAutosize,
} from '@mui/material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 700,
  },
});

interface NodeRowProps {
  nodeRow: Node;
}

const NodeRow: React.FC<NodeRowProps> = ({ nodeRow }) => {
  const [editRow, setEditRow] = useState<boolean>(false);
  const [description, setDescription] = useState<string>(nodeRow.description);
  const [state, setState] = useState<{
    name: string;
    layer: number;
    url: string;
  }>({
    name: nodeRow.name,
    layer: nodeRow.layer,
    url: nodeRow.url,
  });
  const { projectid } = useParams();

  const {
    selectedValuetree,
    setSelectedValuetree,
    currentValuetreeNodes,
    setCurrentValuetreeNodes,
    setCurrentValuetreeLinks,
    currentValuetreeLinks,
  } = useAppContext();

  const EditValuetreeNode = async (changedValuetree: ValuetreeObject) => {
    return API.put(
      'valuetrees',
      `/valuetrees/${selectedValuetree.id}/${projectid}`,
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getAccessToken()
            .getJwtToken()}`,
        },
        body: changedValuetree,
      }
    );
  };

  const confirmEditNodeRow = async (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();

    let updatedNode: UpdatedNode;

    if (state.layer) {
      updatedNode = {
        id: nodeRow.id,
        name: state.name,
        description: description,
        layer: Number(state.layer),
        url: state.url,
      };
    } else {
      updatedNode = {
        id: nodeRow.id,
        name: state.name,
        description: description,
        url: state.url,
      };
    }

    const updatedNodeArray = currentValuetreeNodes.map((nodeObject: Node) =>
      nodeObject.id !== nodeRow.id ? nodeObject : updatedNode
    );

    try {
      const updatedValuetree = {
        ...selectedValuetree,
        links: currentValuetreeLinks,
        nodes: updatedNodeArray,
      };
      setCurrentValuetreeNodes(updatedNodeArray);

      await EditValuetreeNode(updatedValuetree);
      setSelectedValuetree(updatedValuetree);
    } catch (error) {
      console.error(error);
    }

    setEditRow(false);
  };

  const deleteValuetreeNode = async (changedValuetree: ValuetreeObject) => {
    return API.put(
      'valuetrees',
      `/valuetrees/${selectedValuetree.id}/${projectid}`,
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getAccessToken()
            .getJwtToken()}`,
        },
        body: changedValuetree,
      }
    );
  };

  const handleDeleteNodeRow = async (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();

    const updatedNodeArray = currentValuetreeNodes.filter(
      (node: Node) => node.id !== nodeRow.id
    );
    
  const removedNode = currentValuetreeNodes.find((n: any) => n.id === nodeRow.id);
  const linkToUpdate = currentValuetreeLinks.filter((link: any) => link.source !== removedNode.id);
    
    const removedLinksWithoutTarget = linkToUpdate.filter((l: any) => l.target !== removedNode.id)
   try {
      const updatedValuetree = {
        ...selectedValuetree,
        links: removedLinksWithoutTarget,
        nodes: updatedNodeArray,
      };
      setCurrentValuetreeNodes(updatedNodeArray);
      setCurrentValuetreeLinks(removedLinksWithoutTarget)
      await deleteValuetreeNode(updatedValuetree);
      setSelectedValuetree(updatedValuetree); 
      
    } catch (error) {
      console.error(error);
    } 
  };

  const openEditNodeRow = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setEditRow(true);
  };

  const handleChangeState = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeDescription = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setDescription(event.target.value);
  };

  useEffect(() => {

  }, [])

  return (
    <TableRow>
      <TableCell>
        {editRow ? (
          <TextField
            label='Name'
            value={state.name}
            name='name'
            autoComplete='off'
            onChange={handleChangeState}
          />
        ) : (
          nodeRow.name
        )}
      </TableCell>

      <TableCell>
        {editRow ? (
          <TextareaAutosize
            style={{ width: 300 }}
            value={description}
            autoComplete='off'
            onChange={handleChangeDescription}
          />
        ) : (
          <CustomWidthTooltip title={<h1>{nodeRow.description}</h1>}>
            <Box className='description-tableCell'>
              {nodeRow.description.length > 35
                ? `${nodeRow.description.slice(0, 35)}...`
                : nodeRow.description}
            </Box>
          </CustomWidthTooltip>
        )}
      </TableCell>

      <TableCell>
        {editRow ? (
          <TextField
            label='Layer'
            value={state.layer}
            name='layer'
            autoComplete='off'
            onChange={handleChangeState}
          />
        ) : (
          nodeRow.layer
        )}
      </TableCell>
      <TableCell>
        {editRow ? (
          <TextField
            label='Url'
            value={state.url}
            name='url'
            autoComplete='off'
            onChange={handleChangeState}
          />
        ) : (
          <CustomWidthTooltip title={<h1>{nodeRow.url}</h1>}>
            <Box>
              {nodeRow.url.length > 35
                ? `${nodeRow.url.slice(0, 35)}...`
                : nodeRow.url}
            </Box>
          </CustomWidthTooltip>
        )}
      </TableCell>
      <TableCell sx={{ display: 'flex', height: editRow ? '89px' : '' }}>
        {editRow ? (
          <Button
            color='success'
            variant='contained'
            onClick={confirmEditNodeRow}
            startIcon={<CheckIcon />}
          >
            Confirm
          </Button>
        ) : (
          <Tooltip title='Edit' placement='left-start'>
            <Button onClick={openEditNodeRow}>{<EditIcon />}</Button>
          </Tooltip>
        )}

        <Tooltip title='Delete' placement='right-end'>
          <Button onClick={handleDeleteNodeRow} color='error'>
            {<DeleteIcon />}
          </Button>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};
export default NodeRow;
