import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { API, Auth } from 'aws-amplify';
import { v4 as uuid } from 'uuid';
import { useAppContext } from '../../lib/contextLib';
import { ValuetreeObject, Node, SimplifiedNode } from '../types';
import {
  Button,
  TextField,
  TextareaAutosize,
  InputLabel,
  MenuItem,
  FormControl,
  Box,
  Typography,
} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import SendIcon from '@mui/icons-material/Send';
import { SketchPicker } from 'react-color';
import { preventCircularDependencies } from '../../utils/linkDependency';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

interface Props {
  openCreateLink: boolean;
}

const AddLink: React.FC<Props> = ({ openCreateLink }) => {
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const [description, setDescription] = useState<string>('');
  const [selectedSourceValue, setSelectedSourceValue] = useState<string>('');
  const [selectedTargetValue, setSelectedTargetValue] = useState<string>('');
  const [selectedType, setSelectedType] = useState<string>('');
  const [displayColorPicker, setDisplayColorPicker] = useState<boolean>(false);
  const [sourceTargetErrorMsg, setSourceTargetErrorMsg] = useState<string>('');
  const [weightErrorMsg, setWeightErrorMsg] = useState<string>('');
  const [sourceTouched, setSourceTouched] = useState<boolean>(false);
  const [targetTouched, setTargetTouched] = useState<boolean>(false);
  const [typeTouched, setTypeTouched] = useState<boolean>(false);
  const [weightTouched, setWeightTouched] = useState<boolean>(false);
  const [colorTouched, setColorTouched] = useState<boolean>(false);
  const [state, setState] = useState({
    weight: '',
    color: '',
    url: '',
  });
  const { projectid } = useParams();

  const {
    selectedValuetree,
    setSelectedValuetree,
    currentValuetreeLinks,
    currentValuetreeNodes,
    setCurrentValuetreeLinks,
  } = useAppContext();

  useEffect(() => {
    setSourceTouched(false);
    setTargetTouched(false);
    setTypeTouched(false);
    setWeightTouched(false);
    setColorTouched(false);
  }, [openCreateLink]);

  const sourceIsValid = selectedSourceValue.trim() !== '';
  const targetIsValid = selectedTargetValue.trim() !== '';
  const typeIsValid = selectedType.trim() !== '';
  const weightIsValid = state.weight.trim() !== '';
  const colorIsValid = state.color.trim() !== '';

  const sourceInputIsInvalid = !sourceIsValid && sourceTouched;
  const targetInputIsInvalid = !targetIsValid && targetTouched;
  const typeInputIsInvalid = !typeIsValid && typeTouched;
  const weightInputIsInvalid = !weightIsValid && weightTouched;
  const colorInputIsInvalid = !colorIsValid && colorTouched;

  const simplifiedNodes = currentValuetreeNodes.map((nodeObject: Node) => {
    return { name: nodeObject.name, id: nodeObject.id };
  });

  const handleChangeSelectedSource = (event: SelectChangeEvent) => {
    event.preventDefault();
    setSelectedSourceValue(event.target.value);
  };

  const handleChangeSelectedTarget = (event: SelectChangeEvent) => {
    event.preventDefault();
    setSelectedTargetValue(event.target.value);
  };

  const handleShowColorPicker = () => {
    setDisplayColorPicker(true);
  };

  const handleChangeType = (event: SelectChangeEvent) => {
    event.preventDefault();
    setSelectedType(event.target.value);
  };

  const handleChangeState = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeDescription = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setDescription(event.target.value);
  };

  const saveUpdatedValuetree = async (changedValuetree: ValuetreeObject) => {
    return API.put(
      'valuetrees',
      `/valuetrees/${selectedValuetree.id}/${projectid}`,
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getAccessToken()
            .getJwtToken()}`,
        },
        body: changedValuetree,
      }
    );
  };

  const handleSubmitNewLink = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();

    setSourceTouched(true);
    setTargetTouched(true);
    setTypeTouched(true);
    setWeightTouched(true);
    setColorTouched(true);

    if (
      !sourceIsValid ||
      !targetIsValid ||
      !typeIsValid ||
      !weightIsValid ||
      !colorIsValid
    ) {
      return;
    }

    const newLinkObject = {
      id: uuid(),
      source: selectedSourceValue,
      target: selectedTargetValue,
      description: description,
      type: selectedType.toLowerCase(),
      weight: Number(state.weight),
      color: state.color,
      url: state.url,
    };

    try {
      if (selectedSourceValue === selectedTargetValue) {
        setSourceTargetErrorMsg(
          `This link will create a circular loop`
        );
        throw new Error();
      }
      if (Number(state.weight) < 1) {
        setWeightErrorMsg('Selected weight must be at least 1');
      }
      if (
        preventCircularDependencies(currentValuetreeLinks, newLinkObject) ===
        true
      ) {
        setSourceTargetErrorMsg(
          `This link would have created a circular value tree.`
        );
        throw new Error();
      }
      const updatedValueTree = {
        ...selectedValuetree,
        links: [...currentValuetreeLinks, newLinkObject],
        nodes: currentValuetreeNodes,
      };
      await saveUpdatedValuetree(updatedValueTree);
      setSelectedValuetree(updatedValueTree);
      setCurrentValuetreeLinks([...currentValuetreeLinks, newLinkObject]);
      setState({
        weight: '',
        color: '',
        url: '',
      });
      setSelectedSourceValue('');
      setSelectedTargetValue('');
      setSelectedType('');
      setDescription('');
      setSourceTouched(false);
      setTargetTouched(false);
      setTypeTouched(false);
      setWeightTouched(false);
      setColorTouched(false);
      setSourceTargetErrorMsg('');
      setOpenAlert(true);
    } catch (error) {
      setOpenAlert(true);
    }
  };

  const handleCloseAlert = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlert(false);
  };

  const sourceInputBlurHandler = (event: any) => {
    setSourceTouched(true);
  };

  const targetInputBlurHandler = (event: any) => {
    setTargetTouched(true);
  };

  const typeInputBlurHandler = (event: any) => {
    setTypeTouched(true);
  };

  const weightInputBlurHandler = (event: any) => {
    setWeightTouched(true);
  };

  const colorInputBlurHandler = (event: any) => {
    setColorTouched(true);
  };

  return (
    <Box>
      <Snackbar
        open={openAlert}
        autoHideDuration={3000}
        onClose={handleCloseAlert}
      >
        {sourceTargetErrorMsg ? (
          <Alert
            onClose={handleCloseAlert}
            severity='error'
            sx={{ width: '100%' }}
          >
            {sourceTargetErrorMsg}
          </Alert>
        ) : weightErrorMsg ? (
          <Alert
            onClose={handleCloseAlert}
            severity='error'
            sx={{ width: '100%' }}
          >
            {weightErrorMsg}
          </Alert>
        ) : (
          <Alert
            onClose={handleCloseAlert}
            severity='success'
            sx={{ width: '100%' }}
          >
            Link has been added successfully
          </Alert>
        )}
      </Snackbar>
      {openCreateLink && (
        <Box className='input-fields-div'>
          <form onSubmit={handleSubmitNewLink} style={{ display: 'flex' }}>
            <FormControl sx={{ minWidth: 120 }}>
              <InputLabel
                id='selected-source-label'
                sx={{ color: sourceInputIsInvalid ? 'red' : '' }}
              >
                Source
              </InputLabel>
              <Select
                error={sourceInputIsInvalid && true}
                labelId='selected-source-label'
                id='source-selector'
                value={selectedSourceValue}
                label='Source'
                onChange={handleChangeSelectedSource}
                onBlur={sourceInputBlurHandler}
              >
                <MenuItem value=''>None</MenuItem>
                {simplifiedNodes.map((node: SimplifiedNode) => {
                  return (
                    <MenuItem key={node.id} value={node.id}>
                      {node.name}
                    </MenuItem>
                  );
                })}
              </Select>
              <Typography sx={{ color: sourceInputIsInvalid ? 'red' : '' }}>
                *required
              </Typography>
            </FormControl>
            <FormControl sx={{ minWidth: 120, marginLeft: '5px' }}>
              <InputLabel
                id='selected-target-label'
                sx={{ color: targetInputIsInvalid ? 'red' : '' }}
              >
                Target
              </InputLabel>
              <Select
                error={targetInputIsInvalid && true}
                labelId='selected-target-label'
                id='target-selector'
                value={selectedTargetValue}
                label='Target'
                onChange={handleChangeSelectedTarget}
                onBlur={targetInputBlurHandler}
              >
                <MenuItem value=''>None</MenuItem>
                {simplifiedNodes.map((node: SimplifiedNode) => {
                  return (
                    <MenuItem key={node.id} value={node.id}>
                      {node.name}
                    </MenuItem>
                  );
                })}
              </Select>
              <Typography sx={{ color: targetInputIsInvalid ? 'red' : '' }}>
                *required
              </Typography>
            </FormControl>
            <FormControl sx={{ minWidth: 120, marginLeft: '5px' }}>
              <InputLabel
                id='selected-type-label'
                sx={{ color: typeInputIsInvalid ? 'red' : '' }}
              >
                Type
              </InputLabel>
              <Select
                error={typeInputIsInvalid && true}
                labelId='selected-type-label'
                id='type-selector'
                value={selectedType}
                label='Type'
                onChange={handleChangeType}
                onBlur={typeInputBlurHandler}
              >
                <MenuItem value=''>None</MenuItem>
                <MenuItem value={'Connection'}>Connection</MenuItem>
                <MenuItem value={'Weighted'}>Weighted</MenuItem>
              </Select>
              <Typography sx={{ color: typeInputIsInvalid ? 'red' : '' }}>
                *required
              </Typography>
            </FormControl>
            <Box sx={{ marginLeft: '5px' }}>
              <TextField
                error={weightInputIsInvalid && true}
                label='Weight (min: 1)'
                variant='outlined'
                value={state.weight}
                name='weight'
                autoComplete='off'
                onChange={handleChangeState}
                onBlur={weightInputBlurHandler}
              />
              <Typography
                sx={{
                  color: weightInputIsInvalid ? 'red' : '',
                }}
              >
                *required
              </Typography>
            </Box>

            <Box sx={{ width: '195px' }}>
              {displayColorPicker && (
                <Box
                  sx={{
                    position: 'absolute',
                    zIndex: '100',
                  }}
                >
                  <Box
                    sx={{
                      position: 'fixed',
                      top: '0px',
                      right: '0px',
                      bottom: '0px',
                      left: '0px',
                    }}
                    onClick={() => {
                      setDisplayColorPicker(false);
                    }}
                  />
                  <SketchPicker
                    disableAlpha
                    color={state.color}
                    onChange={(color) => {
                      setState({
                        ...state,
                        color: color.hex,
                      });
                    }}
                  />
                </Box>
              )}
              <TextField
                error={colorInputIsInvalid && true}
                label='Color'
                variant='outlined'
                onClick={handleShowColorPicker}
                sx={{ marginLeft: '5px' }}
                value={state.color}
                name='color'
                autoComplete='off'
                onChange={handleChangeState}
                onBlur={colorInputBlurHandler}
              />
              <Typography sx={{ color: colorInputIsInvalid ? 'red' : '' }}>
                *required
              </Typography>
            </Box>
            <TextareaAutosize
              placeholder='Description'
              style={{ marginLeft: '5px', height: '56px' }}
              value={description}
              autoComplete='off'
              onChange={handleChangeDescription}
            />
            <TextField
              label='Url'
              variant='outlined'
              sx={{ marginLeft: '5px' }}
              value={state.url}
              name='url'
              autoComplete='off'
              onChange={handleChangeState}
            />
            <Button
              variant='contained'
              color='success'
              endIcon={<SendIcon />}
              sx={{ marginLeft: '5px', height: '56px' }}
              type='submit'
            >
              Add
            </Button>
          </form>
        </Box>
      )}
    </Box>
  );
};
export default AddLink;
