import React, { useState, useEffect, useRef } from 'react';
import { API, Auth } from 'aws-amplify';
import { useParams } from 'react-router-dom';
import { Box, Typography, IconButton, Collapse, ToggleButton, styled, Button} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { Link, Node } from '../../components/types';
import { ValueTree } from '@spatineo/valuetree-package';
import { useAppContext } from '../../lib/contextLib';
import TabsComponent from '../../components/TabsComponent/TabsComponent';
import { drawerWidth } from '../MainPage/MainPage'
import { getAllLayerValues } from '../../utils/linkDependency'
import './ProjectViewPage.css'

function ProjectViewPage() {
  const [hiddenNodes, setHiddenNodes] = React.useState<Array<string>>([]);
  const [hiddenLayers, setHiddenLayers] = React.useState<Array<number>>([]);
  const [activeClass, setActiveClass] = React.useState<Array<number>>([]);
  const [layersCount, setLayersCount] = React.useState<Array<number>>([]);
  const [clickHideNode, setClickHideNode] = React.useState<{[id: string]: number}>({})
  const [openTreeDetails, setOpenTreeDetails] = useState<boolean>(false);
  const [graphData, setGraphData] = useState({
    nodes: [],
    links: [],
  });
  const {
    selectedValuetree,
    setSelectedValuetree,
    setCurrentValuetreeNodes,
    currentValuetreeNodes,
    setCurrentValuetreeLinks,
    currentValuetreeLinks,
  } = useAppContext();
  const { projectid, valuetreeid } = useParams();

  const printRef = useRef<any>(null);

  const ToggleButtonBoxWrapper = styled(Box)`
  div.css-1u38f73-MuiToggleButtonGroup-root {
    border-radius: 0rem;
    margin-bottom: 2rem;
    }
    div.css-1u38f73-MuiToggleButtonGroup-root button {
      border: none !important;
      border-radius: 50% !important;
      padding: 0rem;
      margin-top: 0rem;
      margin-bottom: 0rem;
      color: grey;
    }
    div.css-1u38f73-MuiToggleButtonGroup-root button: nth-of-type(1) {
      margin-left: 0rem;
    }
  
  `
  const classChecker = (layerNumber: number) => {
    if (activeClass.includes(layerNumber)) {
      const newLayer = activeClass.filter((layer) => layer !== layerNumber);
      setActiveClass(newLayer);
    } else {
      setActiveClass([...activeClass, layerNumber]);
    }
  };

  const handleAddOrRemoveLayer = (layerNumber: number) => {
    if (hiddenLayers.includes(layerNumber)) {
      const newLayer = hiddenLayers.filter((layer) => layer !== layerNumber);
      setHiddenLayers(newLayer);
    } else {
      setHiddenLayers([...hiddenLayers, layerNumber]);
    }
  };

  const handleOpenTreeDetails = () => {
    setOpenTreeDetails(!openTreeDetails);
  };

  const handleHideNodeCheckox = (id: string) => {
    if (hiddenNodes.includes(id)) {
      const newHiddenNode = hiddenNodes.filter((hidden) => hidden !== id);
      setHiddenNodes(newHiddenNode);
    } else {
      
      setHiddenNodes([...hiddenNodes, id]);
    }
  };

  useEffect(() => {
    async function loadValuetree() {
      try {
        const getValuetree =
          valuetreeid &&
          projectid &&
          (await fetchValuetree(valuetreeid, projectid));
        setSelectedValuetree(getValuetree);
        setCurrentValuetreeNodes(getValuetree.nodes);
        setCurrentValuetreeLinks(getValuetree.links);
      } catch (error) {
        console.error(error);
      }
    }

    loadValuetree();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectid, valuetreeid]);

  async function fetchValuetree(valuetreeId: string, projectId: string) {
    return API.get('valuetrees', `/valuetrees/${valuetreeId}/${projectId}`, {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    });
  }

  useEffect(() => {
    let graph = {
      nodes: [] as any,
      links: [] as any,
    };
    if (selectedValuetree === undefined) {
      return;
    }

    if (currentValuetreeLinks.length > 0) {
      currentValuetreeLinks.map((link: Link) => {
        const newLink = {
          source: link.source,
          target: link.target,
          value: link.weight,
          color: link.color,
          type: link.type,
        };
        return graph.links.push(newLink);
      });
    }

    if (currentValuetreeNodes.length > 0) {
      currentValuetreeNodes.map((node: Node) => {
        if (node.layer) {
          const newNode = {
            name: node.name,
            layer: node.layer,
            id: node.id,
            description: node.description,
            url: node.url,
          };
          return graph.nodes.push(newNode);
        }

        const newNode = {
          name: node.name,
          id: node.id,
          description: node.description,
          url: node.url,
        };
        return graph.nodes.push(newNode);
      });
    }

    if(currentValuetreeNodes.length > 0 && currentValuetreeLinks.length > 0) {
      const layersResult = getAllLayerValues(currentValuetreeNodes, currentValuetreeLinks)
      setLayersCount(layersResult.layers)
      setClickHideNode(layersResult.layerPerNode)
    }
    setGraphData(graph);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentValuetreeNodes, currentValuetreeLinks]);

  if (selectedValuetree === undefined) {
    return <div>Loading....</div>;
  }

  const gridCount = layersCount.length

  const showNodeHideWidgets = (layer: number) => {
    const nodesOnThisLayer = Object.keys(clickHideNode).filter(id => clickHideNode[id] === layer).map((id: any)=> graphData.nodes.find((n: any) => n.id === id))
    return (
        <Box>
          <Box>  
            {nodesOnThisLayer.map((nodes: any) => {
            return (
            <Box key={nodes.id}>
              <Box>
              <Button
                style={{color: 'black', textTransform: 'capitalize'}}
                value={nodes.id}
                onClick={() => handleHideNodeCheckox(nodes.id)}>
                  <div 
                    className="hide-node-div" 
                    style={{
                      backgroundColor: hiddenNodes.includes(nodes.id) ? '#4B9CD3' : 'white',
                      border: hiddenNodes.includes(nodes.id) ? '1ps solid rgb(146, 191, 244)' : '1px solid grey'
                    }}>
                  </div>
                  {nodes.name}
              </Button> 
              </Box>
            </Box>
            )
          }
        )}
      
      </Box>
    </Box>
    )
  }
 
  return (
    <Box 
      sx={{ 
        width: `calc(100% - ${drawerWidth}px)`, 
        boxSizing: 'border-box', 
        overflowY: 'hidden', 
        margin: '0rem auto 0rem auto'}}
      >
      <Box  
        sx={{
      
        }}>
        <h3
          style={{
            textAlign: 'center',
            color: 'rgb(52, 118, 203)',
            marginBottom: '4rem'
          }}>
          {Object.keys(selectedValuetree).length > 0 && selectedValuetree.valueTreeName}
        </h3>
        {graphData.nodes.length > 0 && graphData.links.length > 0 ? (
          <Box>
            <Box ref={printRef} >
              <ValueTree
                graphProp={graphData}
                hiddenNodes={hiddenNodes}
                clickToHideNode={undefined}
                hiddenLayers={hiddenLayers}
                zoomGraph={0}
              />
            </Box> 
            <ToggleButtonBoxWrapper>
              <Box sx={{marginBottom: '2rem'}}>
                <Typography> Click to hide layer:</Typography>
              </Box>
              <Box
                sx={{display: 'grid', gridTemplateColumns: `repeat(${gridCount}, 1fr)`}}
                className="click-hide-node-style"
                >
                {layersCount && layersCount.map((layer, index: number) => {
                  return (
                    <Box key={layer} >
                    <ToggleButton
                      value={index}
                      
                      onClick={() => { 
                        handleAddOrRemoveLayer(index)
                        classChecker(index)
                      }}
                      sx={{
                        width: '30px',
                        height: '30px',
                        margin: '0rem 3rem 2rem 0.4rem',
                        border: 'none',
                        backgroundColor: 'lightsteelblue',
                        borderRadius: '50%',
                      }}
                      className={activeClass.includes(index) ? 'active-class-name': ''}
                      >
                        {index + 1}
                    </ToggleButton>
                    <Box>
                    {index === 0 ? <Typography sx={{marginBottom: '0.2rem'}}>
                      Click to hide node:  
                      <IconButton 
                        style={{ width: '30px', marginLeft: '10px' }}
                        onClick={handleOpenTreeDetails} size='small'>
                          {openTreeDetails ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                      </IconButton>
                    </Typography> : <div style={{marginBottom: '2.4rem'}}></div>}
                    <Collapse in={openTreeDetails}>
                     
                      {showNodeHideWidgets(layer)}
                    </Collapse>
                    </Box>
                  </Box>
                  )
                })}
               </Box>
            </ToggleButtonBoxWrapper>
          </Box>
        ) : (
          <Box sx={{ marginLeft: '24px', marginBottom: '24px' }}>
            No valuetree data
          </Box>
        )}
      </Box>
      <TabsComponent printRef={printRef} />
    </Box>
  );
}

export default ProjectViewPage;
