import React from 'react';
import { Auth } from 'aws-amplify';
import backgroundImageTree from '../../../src/assets/tree.jpg';
import GoogleButton from '../../../src/assets/google-sign-in.png';
import './loginPage.css';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth/lib/types';
import { Box } from '@mui/material';

const LoginPage = () => {
  const signIn = async () => {
    try {
      await Auth.federatedSignIn({
        provider: CognitoHostedUIIdentityProvider.Google,
      });
    } catch (error) {
      alert(error);
    }
  };

  return (
    <Box
      className='login-page'
      sx={{
        backgroundImage: `url(${backgroundImageTree})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <Box className='googleButton' onClick={signIn}>
        <img className='googleLogo' src={GoogleButton} alt='' />
      </Box>
    </Box>
  );
};
export default LoginPage;
