import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';
import { API, Auth } from 'aws-amplify';
import { Link, ValuetreeObject, SimplifiedNode, Node } from '../types';
import { useParams } from 'react-router-dom';
import { useAppContext } from '../../lib/contextLib';
import { styled } from '@mui/material/styles';
import {
  Box,
  TableCell,
  TableRow,
  Button,
  TextField,
  TextareaAutosize,
  InputLabel,
  FormControl,
  MenuItem,
  Select,
} from '@mui/material';
// import Select, { SelectChangeEvent } from '@mui/material/Select';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import { SketchPicker } from 'react-color';
import { preventCircularDependencies } from '../../utils/linkDependency';

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 700,
  },
});

interface Props {
  linkRow: Link;
  setOpenAlert: Dispatch<SetStateAction<boolean>>;
  setCreateLinkSuccess: Dispatch<SetStateAction<boolean>>;
  setEditLinkErrorMsg: Dispatch<SetStateAction<string>>;
}

const LinkRow: React.FC<Props> = ({
  linkRow,
  setOpenAlert,
  setCreateLinkSuccess,
  setEditLinkErrorMsg,
}) => {
  const [sourceName, setSourceName] = useState<string>('');
  const [targetName, setTargetName] = useState<string>('');
  const [displayColorPicker, setDisplayColorPicker] = useState<boolean>(false);
  const [editRow, setEditRow] = useState<boolean>(false);
  const [description, setDescription] = useState<string>(linkRow.description);

  const [state, setState] = useState<{
    source: string;
    target: string;
    type: string;
    weight: number;
    color: string;
    url: string;
  }>({
    source: linkRow.source,
    target: linkRow.target,
    type: linkRow.type,
    weight: linkRow.weight,
    color: linkRow.color,
    url: linkRow.url,
  });
  const { projectid } = useParams();

  const {
    selectedValuetree,
    setSelectedValuetree,
    currentValuetreeLinks,
    setCurrentValuetreeLinks,
    currentValuetreeNodes,
  } = useAppContext();

  useEffect(() => {
    const currentSource = currentValuetreeNodes.find(
      (node: Node) => node.id === linkRow.source
    );

    const currentTarget = currentValuetreeNodes.find(
      (node: Node) => node.id === linkRow.target
    );
    
    if(currentSource){
      setSourceName(currentSource.name);
    }
    if(currentTarget){
      setTargetName(currentTarget.name);
    }
   
  }, [linkRow.source, linkRow.target]);

  const simplifiedNodes = currentValuetreeNodes.map(
    (nodeObject: SimplifiedNode) => {
      return { name: nodeObject.name, id: nodeObject.id };
    }
  );

  const handleChangeState = (event: any) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeDescription = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setDescription(event.target.value);
  };

  const handleShowColorPicker = () => {
    setDisplayColorPicker(true);
  };

  const editValuetreeLink = async (changedValuetree: ValuetreeObject) => {
    return API.put(
      'valuetrees',
      `/valuetrees/${selectedValuetree.id}/${projectid}`,
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getAccessToken()
            .getJwtToken()}`,
        },
        body: changedValuetree,
      }
    );
  };

  const confirmEditLinkRow = async (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();

    const updatedLink = {
      id: linkRow.id,
      source: state.source,
      target: state.target,
      type: state.type,
      weight: Number(state.weight),
      description: description,
      color: state.color,
      url: state.url,
    };

    try {
      if (updatedLink.source === updatedLink.target) {
        setState({
          ...state,
          target: '',
        });
        setEditLinkErrorMsg('Not possible to link the same');
        throw new Error();
      }

      if (state.source === '' || state.target === '') {
        setEditLinkErrorMsg('Source and target must be specified');
        throw new Error();
      }

      const updatedLinkArray = currentValuetreeLinks.map((linkObject: Link) =>
        linkObject.id !== linkRow.id ? linkObject : updatedLink
      );

      const updatedValuetree = {
        ...selectedValuetree,
        links: updatedLinkArray,
        nodes: currentValuetreeNodes,
      };

      if (preventCircularDependencies(currentValuetreeLinks, updatedLink)) {
        throw new Error();
      }

      if (preventCircularDependencies(updatedValuetree.links, updatedLink)) {
        setEditLinkErrorMsg('This would create a circular link');
        throw new Error();
      }
      setCurrentValuetreeLinks(updatedLinkArray);

      await editValuetreeLink(updatedValuetree);
      setSelectedValuetree(updatedValuetree);
      setCreateLinkSuccess(true);
      setOpenAlert(true);
    } catch (error) {
      alert(error);
    }

    setEditRow(false);
  };

  const deleteValuetreeLink = async (changedValuetree: ValuetreeObject) => {
    return API.put(
      'valuetrees',
      `/valuetrees/${selectedValuetree.id}/${projectid}`,
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getAccessToken()
            .getJwtToken()}`,
        },
        body: changedValuetree,
      }
    );
  };

  const handleDeleteLinkRow = async (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();

    const updatedLinkArray = currentValuetreeLinks.filter(
      (link: Link) => link.id !== linkRow.id
    );

    try {
      const updatedValuetree = {
        ...selectedValuetree,
        links: updatedLinkArray,
        nodes: currentValuetreeNodes,
      };
      setCurrentValuetreeLinks(updatedLinkArray);
      await deleteValuetreeLink(updatedValuetree);
      setSelectedValuetree(updatedValuetree);
    } catch (error) {
      console.error(error);
    }
  };

  const openEditLinkRow = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setEditRow(true);
  };

  return (
    <TableRow>
      <TableCell sx={{ width: '20%' }}>
        {editRow ? (
          <FormControl fullWidth>
            <InputLabel id='input-source'>Source</InputLabel>
            <Select
              labelId='input-source'
              id='select-source'
              value={state.source}
              label='Source'
              name='source'
              onChange={handleChangeState}
            >
              {simplifiedNodes.map((node: SimplifiedNode) => (
                <MenuItem key={node.id} value={node.id}>
                  {node.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : (
          sourceName
        )}
      </TableCell>
      <TableCell sx={{ width: '20%' }}>
        {editRow ? (
          <FormControl fullWidth>
            <InputLabel id='input-target'>Target</InputLabel>
            <Select
              labelId='input-target'
              id='select-target'
              name='target'
              value={state.target}
              label='Target'
              onChange={handleChangeState}
            >
              {simplifiedNodes.map((node: SimplifiedNode) => (
                <MenuItem key={node.id} value={node.id}>
                  {node.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : (
          targetName
        )}
      </TableCell>
      <TableCell sx={{ width: '12%' }}>
        {editRow ? (
          <FormControl fullWidth>
            <InputLabel id='input-type'>Type</InputLabel>
            <Select
              labelId='input-type'
              id='type-selector'
              value={state.type}
              label='Type'
              name='type'
              onChange={handleChangeState}
            >
              <MenuItem value=''>None</MenuItem>
              <MenuItem value='connection'>connection</MenuItem>
              <MenuItem value='weighted'>weighted</MenuItem>
            </Select>
          </FormControl>
        ) : (
          linkRow.type
        )}
      </TableCell>
      <TableCell sx={{ width: '5%' }}>
        {editRow ? (
          <TextField
            label='Weight'
            value={state.weight}
            name='weight'
            autoComplete='off'
            onChange={handleChangeState}
          />
        ) : (
          linkRow.weight
        )}
      </TableCell>
      <TableCell sx={{ width: '7,5%' }}>
        {editRow ? (
          <Box>
            {displayColorPicker && (
              <Box
                sx={{
                  position: 'absolute',
                  zIndex: '100',
                }}
              >
                <Box
                  sx={{
                    position: 'fixed',
                    top: '0px',
                    right: '0px',
                    bottom: '0px',
                    left: '0px',
                  }}
                  onClick={() => {
                    setDisplayColorPicker(false);
                  }}
                />
                <SketchPicker
                  disableAlpha
                  color={state.color}
                  onChange={(color) => {
                    setState({
                      ...state,
                      color: color.hex,
                    });
                  }}
                />
              </Box>
            )}
            <TextField
              label='Color'
              value={state.color}
              name='color'
              autoComplete='off'
              onClick={handleShowColorPicker}
              onChange={handleChangeState}
            />
          </Box>
        ) : (
          linkRow.color
        )}
      </TableCell>
      <TableCell sx={{ width: '7,5%' }}>
        {editRow ? (
          <TextareaAutosize
            value={description}
            autoComplete='off'
            onChange={handleChangeDescription}
          />
        ) : (
          <CustomWidthTooltip title={<h1>{linkRow.description}</h1>}>
            <Box className='description-tableCell'>
              {linkRow.description.length > 37
                ? `${linkRow.description.slice(0, 37)}...`
                : linkRow.description}
            </Box>
          </CustomWidthTooltip>
        )}
      </TableCell>
      <TableCell sx={{ width: '5%' }}>
        {editRow ? (
          <TextField
            label='Url'
            value={state.url}
            name='url'
            autoComplete='off'
            onChange={handleChangeState}
          />
        ) : (
          <CustomWidthTooltip title={<h1>{linkRow.url}</h1>}>
            <Box className='url-tableCell'>
              {linkRow.url.length > 35
                ? `${linkRow.url.slice(0, 35)}...`
                : linkRow.url}
            </Box>
          </CustomWidthTooltip>
        )}
      </TableCell>
      <TableCell sx={{ width: '5%' }}>
        {editRow ? (
          <Tooltip title='Confirm'>
            <Button
              color='success'
              variant='contained'
              onClick={confirmEditLinkRow}
            >
              {<CheckIcon />}
            </Button>
          </Tooltip>
        ) : (
          <Tooltip title='Edit' placement='left-start'>
            <Button onClick={openEditLinkRow}>{<EditIcon />}</Button>
          </Tooltip>
        )}
      </TableCell>
      <TableCell sx={{ width: '5%' }}>
        {editRow ? null : (
          <Button onClick={handleDeleteLinkRow} color='error'>
            {<DeleteIcon />}
          </Button>
        )}
      </TableCell>
    </TableRow>
  );
};
export default LinkRow;
