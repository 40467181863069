import * as React from 'react';
import { Button, Box, TextField, Modal, Typography } from '@mui/material';
import './createProject.css';

interface CreateProjectProps {
  name: string;
  description: string;
  openNewProjectModal: boolean;
  handleChangename: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeDescription: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleCloseCreateNewProject: (event: React.MouseEvent) => void;
  handleSubmit: (event: React.FormEvent) => void;
}
const CreateProject = (props: CreateProjectProps) => {
  const {
    name,
    description,
    openNewProjectModal,
    handleChangename,
    handleChangeDescription,
    handleCloseCreateNewProject,
    handleSubmit,
  } = props;

  return (
    <Box>
      <Modal
        hideBackdrop
        open={openNewProjectModal}
        onClose={handleCloseCreateNewProject}
        aria-labelledby='modal-title'
        aria-describedby='modal-description'
      >
        <Box
          className='create-new-project-modal'
          sx={{ bgcolor: 'background.paper' }}
        >
          <Typography
            id='modal-title'
            variant='h6'
            component='h2'
            sx={{ textAlign: 'center' }}
          >
            Create Project
          </Typography>
          <Box sx={{ p: 0.8, display: 'flex', flexDirection: 'row' }}>
            <TextField
              label='Name'
              variant='outlined'
              id='name'
              value={name}
              size='small'
              onChange={handleChangename}
              sx={{ width: '100%' }}
              autoComplete='off'
            />
          </Box>
          <Box sx={{ p: 0.8, display: 'flex', flexDirection: 'row' }}>
            <TextField
              label='Description'
              variant='outlined'
              id='description'
              value={description}
              size='small'
              onChange={handleChangeDescription}
              sx={{ width: '100%' }}
              autoComplete='off'
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '4%',
              width: '100%',
              margin: 'auto',
              justifyContent: 'center',
              padding: '20px 0',
            }}
          >
            <Button
              onClick={handleSubmit}
              type='submit'
              variant='outlined'
              disabled={!name}
              sx={{ width: '30%', padding: '10px 0px' }}
            >
              Add project
            </Button>
            <Button
              onClick={handleCloseCreateNewProject}
              variant='outlined'
              color='warning'
              sx={{ width: '30%', padding: '10px 0px' }}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default CreateProject;
