import * as React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material/';

interface DeleteDialogProps {
  openDeleteDialog: boolean;
  handleCloseDeleteDialog: (event: React.MouseEvent<HTMLButtonElement>) => void;
  title: string;
  content: string;
  handleAgreeDelete: React.MouseEventHandler<HTMLButtonElement>;
}

const DeleteDialog: React.FC<DeleteDialogProps> = ({
  openDeleteDialog,
  title,
  content,
  handleCloseDeleteDialog,
  handleAgreeDelete,
}) => {
  return (
    <>
      <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog}>Disagree</Button>
          <Button onClick={handleAgreeDelete} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteDialog;
