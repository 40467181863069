import { Link, Node } from '../components/types';

function preventCircularDependencies(links: Link[],  proposedLink: {source: string, target: string}){
  function findConnectedLinks(links: Link[], fSource: string, fTarget: string) {
    const foundLinks = links.filter(link => link.source === fSource)
    const directLinksFromfSourceTofTarget = foundLinks.filter(link => link.target === fTarget)
    if(directLinksFromfSourceTofTarget.length > 0){
      return true
    } 
    for(let link in foundLinks){
      let connectedLinksSearch = findConnectedLinks(links, foundLinks[link].target, fTarget)
      if(connectedLinksSearch){
        return true
      }
    }
    return false
  }
  return findConnectedLinks(links, proposedLink.target, proposedLink.source)
}

function calculateNodeLayer(node: Node, nodes: Node[], links: Link[], visited: {[id: string]:number}): number {

  if(visited[node.id] !== undefined){
    return visited[node.id]
  }
  
  if(node.layer !== undefined){
      visited[node.id] = node.layer
      return node.layer
    }
  
    const linksEqualToNode = links.filter((l) => l.target === node.id)
    if(linksEqualToNode.length === 0){
      visited[node.id] = 0
      return 0
    }

    const layerValue = linksEqualToNode.map((link) => {
      const findNode = nodes.find((n) => n.id === link.source)
      if(findNode !== undefined){
        const recussionCall = calculateNodeLayer(findNode, nodes, links, visited)
        return recussionCall
      } else {
        throw new Error("Link without source");
      }
    })

  visited[node.id] = Math.max(...layerValue) + 1
  return Math.max(...layerValue) + 1
}

function getAllLayerValues (nodes: Node[], links: Link[]){
  const layerPerNode: any = {}
  const mapAllNodes = nodes.map((node) => calculateNodeLayer(node, nodes, links, layerPerNode ))
  const layers: number[] = []
  mapAllNodes.reduce(function(acc , val: number) {
    if(!acc.includes(val)){
      acc.push(val)
    }
    return acc
  }, layers)

  return {
    layers,
    layerPerNode
  }
}

export {
  preventCircularDependencies,
  getAllLayerValues
}
