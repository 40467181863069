import React, { useState, useEffect } from 'react';
import './App.css';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { Project } from './components/types';
import { AppContext } from './lib/contextLib';
import LoginPage from './pages/LoginPage/LoginPage';
import MainPage from './pages/MainPage/MainPage';
import ProjectsPage from './pages/ProjectsPage/ProjectsPage';
import NotFound from './components/NotFound/NotFound';
import ProjectViewPage from './pages/ProjectViewPage/ProjectViewPage';
import { ValuetreeObject, ValuetreeMeta } from './components/types';

function App() {
  const [isAuthenticated, userHasAuthenticated] = useState<boolean>(false);
  const [listOfProjects, setListOfProjects] = useState<Project[]>([]);
  const [copyFromValueTreeId, setCopyFromValueTreeId] = useState<string>('');
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [selectedValuetree, setSelectedValuetree] = useState<
    ValuetreeObject | {}
  >({});
  const [selectedProjectValuetrees, setSelectedProjectValuetrees] = useState<
    ValuetreeMeta[] | null
  >(null);
  const [selectedProject, setSelectedProject] = useState<Project | null>(null);
  const [currentValuetreeNodes, setCurrentValuetreeNodes] = useState<[]>([]);
  const [currentValuetreeLinks, setCurrentValuetreeLinks] = useState<[]>([]);
  const [user, setUser] = useState(null);

  const navigate = useNavigate();
  const getUser = async () => {
    const user = await Auth.currentUserInfo();
    if (user) setUser(user);
  };

  useEffect(() => {
    getUser();
    onLoad();
  }, []);

  async function onLoad() {
    try {
      await Auth.currentSession();
      userHasAuthenticated(true);
    } catch (error) {
      navigate('/');
    }
  }

  return (
    <div className='App'>
      <AppContext.Provider
        value={{
          isAuthenticated,
          listOfProjects,
          selectedValuetree,
          selectedProjectValuetrees,
          copyFromValueTreeId,
          currentValuetreeNodes,
          currentValuetreeLinks,
          user,
          openDrawer,
          selectedProject,
          setSelectedProject,
          setOpenDrawer,
          setCurrentValuetreeLinks,
          setCurrentValuetreeNodes,
          setCopyFromValueTreeId,
          setSelectedProjectValuetrees,
          setListOfProjects,
          userHasAuthenticated,
          setSelectedValuetree,
        }}
      >
        <Routes>
          <Route path='/' element={<LoginPage />} />
          <Route path='/projects' element={<MainPage />}>
            <Route index element={<ProjectsPage />} />
            <Route path='/projects/:projectid' element={<ProjectsPage />} />
            <Route
              path='/projects/:projectid/:valuetreeid'
              element={<ProjectViewPage />}
            />
          </Route>
          <Route path='*' element={<NotFound />} />
        </Routes>
      </AppContext.Provider>
    </div>
  );
}

export default App;
