import React, { useState } from 'react';
import { Node } from '../types';
import { useAppContext } from '../../lib/contextLib';
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Button,
  Tooltip,
  Box,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import NodeRow from './NodeRow';
import CreateNode from './CreateNode';
import './nodesTab.css';

const NodesTab: React.FC = () => {
  const [openCreateNode, setOpenCreateNode] = useState<boolean>(false);
  const handleShowCreateNewNode = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    setOpenCreateNode(!openCreateNode);
  };
  const { currentValuetreeNodes } = useAppContext();

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: '20px 5px',
        }}
      >
        <Box sx={{ margin: '10px 10px 10px 0px' }}>
          {openCreateNode ? (
            <Tooltip title='Cancel'>
              <Button
                sx={{ width: '110px' }}
                size='small'
                variant='contained'
                color='warning'
                onClick={handleShowCreateNewNode}
              >
                {<CloseIcon />} Cancel
              </Button>
            </Tooltip>
          ) : (
            <Tooltip title='Add node'>
              <Button
                sx={{ width: '110px' }}
                variant='contained'
                color='success'
                onClick={handleShowCreateNewNode}
              >
                {<AddIcon />}
              </Button>
            </Tooltip>
          )}
        </Box>
        <CreateNode openCreateNode={openCreateNode} />
      </Box>
      <TableContainer component={Paper} sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: '300px' }}>Node</TableCell>
              <TableCell sx={{ width: '300px' }}>Description</TableCell>
              <TableCell sx={{ width: '300px' }}>Layer</TableCell>
              <TableCell sx={{ width: '300px' }}>URL</TableCell>
              <TableCell>&nbsp;</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentValuetreeNodes.map((nodeRow: Node) => {
              return <NodeRow key={nodeRow.id} nodeRow={nodeRow} />;
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
export default NodesTab;
