import React, { useEffect, useState } from 'react';
import { API, Auth } from 'aws-amplify';
import { useAppContext } from '../../lib/contextLib';
import { v4 as uuid } from 'uuid';
import { useParams } from 'react-router-dom';
import {
  Button,
  TextField,
  TextareaAutosize,
  Box,
  Typography,
} from '@mui/material';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import SendIcon from '@mui/icons-material/Send';
import './nodesTab.css';
import { ValuetreeObject } from '../types';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

interface CreateNodeProps {
  openCreateNode: boolean;
}

const CreateNode: React.FC<CreateNodeProps> = ({ openCreateNode }) => {
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [nameTouched, setNameTouched] = useState<boolean>(false);
  const [state, setState] = useState({
    layer: '',
    url: '',
  });
  const { projectid } = useParams();

  const {
    setSelectedValuetree,
    selectedValuetree,
    currentValuetreeNodes,
    setCurrentValuetreeNodes,
    currentValuetreeLinks,
  } = useAppContext();

  useEffect(() => {
    setNameTouched(false);
  }, [openCreateNode]);

  const enteredNameIsValid = name.trim() !== '';
  const nameInputIsInvalid = !enteredNameIsValid && nameTouched;

  const handleChangeState = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleChangeDescription = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setDescription(event.target.value);
  };

  const saveUpdatedValuetree = async (changedValuetree: ValuetreeObject) => {
    return API.put(
      'valuetrees',
      `/valuetrees/${selectedValuetree.id}/${projectid}`,
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getAccessToken()
            .getJwtToken()}`,
        },
        body: changedValuetree,
      }
    );
  };

  const nameInputBlurHandler = (event: any) => {
    setNameTouched(true);
  };

  const handleSubmitNewNode = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();

    setNameTouched(true);

    if (!enteredNameIsValid) {
      return;
    }

    const capitalizeName = name.charAt(0).toUpperCase() + name.slice(1);

    let newNode: any;

    if (state.layer === '') {
      newNode = {
        id: uuid(),
        name: capitalizeName,
        description: description,
        url: state.url,
      };
    } else {
      newNode = {
        id: uuid(),
        name: capitalizeName,
        description: description,
        layer: Number(state.layer),
        url: state.url,
      };
    }
    try {
      const updatedValueTree = {
        ...selectedValuetree,
        links: currentValuetreeLinks,
        nodes: [...currentValuetreeNodes, newNode],
      };
      saveUpdatedValuetree(updatedValueTree);
      setCurrentValuetreeNodes([...currentValuetreeNodes, newNode]);
      setSelectedValuetree(updatedValueTree);
      setName('');
      setNameTouched(false);
      setDescription('');
      setState({
        layer: '',
        url: '',
      });
      setOpenAlert(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCloseAlert = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlert(false);
  };

  return (
    <Box>
      <Snackbar
        open={openAlert}
        autoHideDuration={3000}
        onClose={handleCloseAlert}
      >
        <Alert
          onClose={handleCloseAlert}
          severity='success'
          sx={{ width: '100%' }}
        >
          Node has been added successfully
        </Alert>
      </Snackbar>
      {openCreateNode && (
        <Box className='input-fields-div'>
          <form
            onSubmit={handleSubmitNewNode}
            style={{ display: 'flex', flexDirection: 'row' }}
          >
            <Box>
              <TextField
                error={nameInputIsInvalid && true}
                label='Name'
                variant='outlined'
                sx={{ width: '295px' }}
                value={name}
                autoComplete='off'
                onChange={handleChangeName}
                onBlur={nameInputBlurHandler}
              />
              <Typography sx={{ color: nameInputIsInvalid ? 'red' : '' }}>
                *required
              </Typography>
            </Box>

            <TextareaAutosize
              placeholder='Description'
              style={{ width: '295px', marginLeft: '5px', minHeight: '56px' }}
              value={description}
              autoComplete='off'
              onChange={handleChangeDescription}
            />
            <TextField
              label='Layer'
              variant='outlined'
              sx={{ width: '295px', marginLeft: '5px' }}
              value={state.layer}
              name='layer'
              autoComplete='off'
              onChange={handleChangeState}
            />
            <TextField
              label='Url'
              variant='outlined'
              sx={{ width: '295px', marginLeft: '5px' }}
              value={state.url}
              name='url'
              autoComplete='off'
              onChange={handleChangeState}
            />
            <Button
              variant='contained'
              color='success'
              endIcon={<SendIcon />}
              sx={{ marginLeft: '5px', height: '56px' }}
              type='submit'
            >
              Add
            </Button>
          </form>
        </Box>
      )}
    </Box>
  );
};
export default CreateNode;
