import React, { useEffect, useState } from 'react';
import { API, Auth } from 'aws-amplify';
import { ValuetreeMeta, ValuetreeObject } from '../types';
import { useAppContext } from '../../lib/contextLib';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Button,
  TextField,
  TextareaAutosize,
  InputLabel,
  IconButton,
  Box,
  Tooltip,
  ClickAwayListener,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import html2canvas from 'html2canvas';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteDialog from '../DeleteDialog/DeleteDialog';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import config from '../../config';

interface Props {
  printRef: any;
}

const ExportTreeTab: React.FC<Props> = ({ printRef }) => {
  const [inputFieldsDisabled, setInputFieldsDisabled] = useState<boolean>(true);
  const [valuetreeName, setValuetreeTreeName] = useState<string>('');
  const [valuetreeDescription, setValuetreeDescription] = useState<string>('');
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [openCopyTooltip, setOpenCopyTooltip] = useState<boolean>(false);
  const { projectid, valuetreeid } = useParams();

  const devURL = `http://localhost:3001/api/projects/${projectid}/${valuetreeid}`;
  const publicURL = `https://public.valuetree.spatineo.com/api/projects/${projectid}/${valuetreeid}`;
  const valuetreeURL = config.stage.STAGE_NAME === 'prod' ? publicURL : devURL;

  const {
    selectedValuetree,
    setSelectedValuetree,
    selectedProjectValuetrees,
    setSelectedProjectValuetrees,
  } = useAppContext();
  const navigate = useNavigate();

  useEffect(() => {
    setValuetreeTreeName(selectedValuetree.valueTreeName);
    setValuetreeDescription(selectedValuetree.description);
  }, [selectedValuetree.valueTreeName, selectedValuetree.description]);

  const handleChangeValuetreeName = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setValuetreeTreeName(event.target.value);
  };

  const handleChangeValuetreeDescription = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setValuetreeDescription(event.target.value);
  };

  const handleEnableInputFields = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();

    setInputFieldsDisabled(false);
  };

  const editValuetree = async (changedValuetree: ValuetreeObject) => {
    return API.put('valuetrees', `/valuetrees/${valuetreeid}/${projectid}`, {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
      body: changedValuetree,
    });
  };

  const handleConfirmInputFields = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    const updatedValuetree = {
      ...selectedValuetree,
      valueTreeName: valuetreeName,
      description: valuetreeDescription,
    };
    await editValuetree(updatedValuetree);
    setSelectedValuetree(updatedValuetree);
    const savedUpdatedValuetree = {
      id: selectedValuetree.id,
      valueTreeName: valuetreeName,
    };
    setSelectedProjectValuetrees(
      selectedProjectValuetrees.map((valueTree: ValuetreeMeta) =>
        valueTree.id !== selectedValuetree.id
          ? valueTree
          : savedUpdatedValuetree
      )
    );
    setInputFieldsDisabled(true);
  };

  const savePublishedValuetree = async (changedValuetree: any) => {
    return API.put(
      'valuetrees',
      `/valuetrees/${selectedValuetree.id}/${projectid}`,
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getAccessToken()
            .getJwtToken()}`,
        },
        body: changedValuetree,
      }
    );
  };

  const handlePublishValuetree = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    const updatedValuetree = {
      ...selectedValuetree,
      published: true,
    };
    try {
      savePublishedValuetree(updatedValuetree);
      setSelectedValuetree(updatedValuetree);
    } catch (error) {
      console.error(error);
    }
  };

  const handleUnpublishValuetree = () => {
    const updatedValuetree = {
      ...selectedValuetree,
      published: false,
    };
    try {
      savePublishedValuetree(updatedValuetree);
      setSelectedValuetree(updatedValuetree);
    } catch (error) {
      console.error(error);
    }
  };

  const handleExportPNG = async () => {
    const element = printRef.current;
    const canvas = await html2canvas(element);

    const data = canvas.toDataURL('image/png');
    const link = document.createElement('a');

    if (typeof link.download === 'string') {
      link.href = data;
      link.download = 'valuetree.png';

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      window.open(data);
    }
  };

  const deleteValueTree = async (valueTreeId: string) => {
    return API.del('valuetrees', `/valuetrees/${valueTreeId}/${projectid}`, {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    });
  };

  const handleDeleteValueTree = async (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    try {
      valuetreeid && (await deleteValueTree(valuetreeid));
      setSelectedProjectValuetrees(
        selectedProjectValuetrees.filter(
          (valuetree: ValuetreeMeta) => valuetree.id !== valuetreeid
        )
      );
    } catch (error) {
      alert(error);
    }
    navigate(`/projects/${projectid}/${selectedProjectValuetrees[0].id}`);
    setOpenDeleteDialog(false);
  };

  const handleCopyLinkToClipboard = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    setOpenCopyTooltip(true);
    navigator.clipboard.writeText(valuetreeURL);
  };

  const handleCloseCopyTooltip = () => {
    setOpenCopyTooltip(false);
  };

  const handleOpenDeleteDialog = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setOpenDeleteDialog(false);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        borderTop: '1px solid lightgray',
        paddingTop: '20px',
      }}
    >
      <Box>
        <form onSubmit={handleConfirmInputFields}>
          <Box>
            <TextField
              label='Valuetree name'
              autoComplete='off'
              sx={{ width: 300, margin: '5px' }}
              value={valuetreeName}
              onChange={handleChangeValuetreeName}
              disabled={inputFieldsDisabled}
            />
            <InputLabel sx={{ margin: '10px 0px 0px 5px' }}>
              Description
            </InputLabel>
            <TextareaAutosize
              style={{ width: 300, margin: '5px', minHeight: '100px' }}
              value={valuetreeDescription}
              onChange={handleChangeValuetreeDescription}
              disabled={inputFieldsDisabled}
            />
          </Box>
          {inputFieldsDisabled ? (
            <Button
              sx={{ height: '30px', margin: '5px' }}
              startIcon={<EditIcon />}
              color='warning'
              variant='contained'
              onClick={handleEnableInputFields}
            >
              Edit
            </Button>
          ) : (
            <Button
              sx={{ height: '30px', margin: '5px' }}
              color='success'
              variant='contained'
              type='submit'
            >
              <CheckIcon />
            </Button>
          )}
          <Button
            sx={{ height: '30px', marginLeft: '30px' }}
            variant='contained'
            color='error'
            startIcon={<DeleteIcon />}
            onClick={handleOpenDeleteDialog}
          >
            Delete Valutree
          </Button>
          <DeleteDialog
            openDeleteDialog={openDeleteDialog}
            handleCloseDeleteDialog={handleCloseDeleteDialog}
            title={`You are about to delete ${selectedValuetree.valueTreeName}`}
            content={'This will permanently delete all data in this value tree'}
            handleAgreeDelete={handleDeleteValueTree}
          />
        </form>
      </Box>
      <Box sx={{ marginLeft: '100px' }}>
        <h2
          style={{
            textAlign: 'center',
            color: 'gray',
            borderBottom: '1px solid lightgray',
          }}
        >
          Export
        </h2>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            height: '100px',
          }}
        >
          {!selectedValuetree.published ? (
            <Button
              variant='contained'
              sx={{ height: '56px', width: '140px', marginLeft: '10px' }}
              onClick={handlePublishValuetree}
            >
              Publish
            </Button>
          ) : (
            <Button
              variant='contained'
              color='warning'
              sx={{ height: '56px', width: '140px', marginLeft: '10px' }}
              onClick={handleUnpublishValuetree}
            >
              Unpublish
            </Button>
          )}
          <Button
            variant='contained'
            sx={{ height: '56px', width: '140px', marginLeft: '10px' }}
            onClick={handleExportPNG}
          >
            Export PNG
          </Button>
        </Box>
        {selectedValuetree.published && (
          <Box sx={{ display: 'flex' }}>
            <Box>
              <TextField
                label='Public link'
                onFocus={(event) => {
                  event.target.select();
                }}
                value={valuetreeURL}
                sx={{ width: '300px' }}
              />
            </Box>
            <ClickAwayListener onClickAway={handleCloseCopyTooltip}>
              <Box>
                <Tooltip
                  PopperProps={{
                    disablePortal: true,
                  }}
                  onClose={handleCloseCopyTooltip}
                  open={openCopyTooltip}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  title='Copied'
                >
                  <IconButton onClick={handleCopyLinkToClipboard}>
                    <ContentCopyIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </ClickAwayListener>
          </Box>
        )}
      </Box>
    </Box>
  );
};
export default ExportTreeTab;
