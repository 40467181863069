import React, { useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';

import { API, Auth } from 'aws-amplify';
import { Box } from '@mui/material/';
import { useAppContext } from '../../lib/contextLib';
import './mainPage.css';
import TopNavbar from '../../components/TopNavbar/TopNavbar';
import SideMenuDrawer from '../../components/SideMenuDrawer/SideMenuDrawer';

export const drawerWidth = 240;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

const MainPage: React.FC = () => {
  const { setListOfProjects, isAuthenticated, openDrawer, setSelectedProject } =
    useAppContext();
  const { projectid } = useParams();

  useEffect(() => {
    async function fetchSelectedProjectData() {
      try {
        const foundSelectedProject =
          projectid && (await fetchProject(projectid));
        setSelectedProject(foundSelectedProject);
      } catch (error) {
        console.error(error);
      }
    }

    projectid && fetchSelectedProjectData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectid]);

  const fetchProject = async (projectId: string) => {
    return API.get('projects', `/projects/${projectId}`, {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    });
  };

  useEffect(() => {
    async function onLoad() {
      try {
        const getProjects = await fetchProjects();
        setListOfProjects(getProjects);
      } catch (error) {
        alert(error);
      }
    }

    onLoad();
  }, [setListOfProjects]);

  const fetchProjects = async () => {
    return API.get('projects', '/projects', {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    });
  };

  return (
    isAuthenticated && (
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <TopNavbar AppBar={AppBar} />
        <SideMenuDrawer drawerWidth={drawerWidth} />
        <Main open={openDrawer} style={{ marginTop: '10vh' }}>
          <Outlet />
        </Main>
      </Box>
    )
  );
};

export default MainPage;
