import React, { useState } from 'react';
import { API, Auth } from 'aws-amplify';
import { Project } from '../../components/types';
import { NavLink, useNavigate } from 'react-router-dom';
import {
  TableCell,
  TableRow,
  Button,
  Box,
  TextField,
  Checkbox,
  Tooltip,
  Typography,
  IconButton,
  ClickAwayListener,
} from '@mui/material';
import { useAppContext } from '../../lib/contextLib';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import DeleteDialog from '../../components/DeleteDialog/DeleteDialog';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import config from '../../config';

interface Props {
  projectRow: Project;
}

const ProjectRow: React.FC<Props> = ({ projectRow }) => {
  const { projectName, description, lastModified } = projectRow;
  const devURL = 'http://localhost:3001/api/projects';
  const publicURL = 'https://public.valuetree.spatineo.com/api/projects';
  const [currentProjectName, setCurrentProjectName] = useState(projectName);
  const [currentDescription, setCurrentDescription] = useState(description);
  const [editRow, setEditRow] = useState(false);
  const [openCopyTooltip, setOpenCopyTooltip] = useState<boolean>(false);
  const [publishProject, setPublishProject] = useState<boolean>(
    projectRow.published
  );
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const { listOfProjects, setListOfProjects, selectedProjectValuetrees } =
    useAppContext();
  const navigate = useNavigate();
  const projectURL =
    config.stage.STAGE_NAME === 'prod'
      ? `${publicURL}/${projectRow.id}`
      : `${devURL}/${projectRow.id}`;

  let activeClassName = 'highlight-color';

  const editProject = async (changedProject: Project) => {
    return API.put('projects', `/projects/${projectRow.id}`, {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
      body: changedProject,
    });
  };

  const dialongContent =
    'Deleting this project will permanently remove the project and all value trees in it.';

  const handleChangeProjectName = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCurrentProjectName(event.target.value);
  };

  const handleCurrentDescriptionEdit = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCurrentDescription(event.target.value);
  };

  const handleTogglePublishProject = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: boolean
  ) => {
    setPublishProject(value);
  };

  const handleOpenEditProjectDetails = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    setEditRow(true);
  };

  const confirmEditProject = async (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();

    const updatedProject = {
      ...projectRow,
      projectName: currentProjectName,
      published: publishProject,
      description: currentDescription,
    };

    const updatedProjectArray = listOfProjects.map((project: Project) =>
      project.id !== projectRow.id ? project : updatedProject
    );

    try {
      setListOfProjects(updatedProjectArray);
      await editProject(updatedProject);
    } catch (error) {
      alert(error);
    }
    setEditRow(false);
  };

  const deleteProjectRow = async (rowId: string) => {
    return API.del('projects', `/projects/${rowId}`, {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    });
  };

  const deleteProjectValuetrees = async (foundId: string) => {
    return API.del('valuetrees', `/valuetrees/${foundId}/${projectRow.id}`, {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    });
  };

  const handleDeleteProject = async (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();

    try {
      setListOfProjects(
        listOfProjects.filter(
          (project: Project) => project.id !== projectRow.id
        )
      );
      navigate('/projects');
      await deleteProjectRow(projectRow.id);

      if (selectedProjectValuetrees.length > 0) {
        // eslint-disable-next-line array-callback-return
        await selectedProjectValuetrees.map((valuetree: { id: string }) => {
          deleteProjectValuetrees(valuetree.id);
        });
      }
    } catch (error) {
      console.error(error);
    }
    setOpenDeleteDialog(false);
  };

  const handleCloseCopyTooltip = () => {
    setOpenCopyTooltip(false);
  };

  const handleCopyLinkToClipboard = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    setOpenCopyTooltip(true);
    navigator.clipboard.writeText(projectURL);
  };

  const handleOpenDeleteDialog = () => {
    setOpenDeleteDialog(true);
  };

  const readableDate = (date: string) => {
    const splittedDate = date.split('T')
    const extractTime = splittedDate[1].split(':')
    const extractDate = new Date(`${splittedDate[0]}`).toDateString().split(' ').slice(1).join(' ')
    const formattedDate = `${extractTime[0]}:${extractTime[1]} ${extractDate}`
    return formattedDate
 }

  return (
    <TableRow>
      <TableCell>
        {editRow ? (
          <Box sx={{ display: 'flex' }}>
            <Box>
              <TextField
                label='Name'
                value={currentProjectName}
                autoComplete='off'
                onChange={handleChangeProjectName}
              />
            </Box>
            <Box sx={{ marginLeft: '50px' }}>
              <Typography>Publish</Typography>
              <Checkbox
                value={publishProject}
                onChange={handleTogglePublishProject}
                checked={publishProject && true}
              />
            </Box>
          </Box>
        ) : (
          <Box>
            <NavLink
              to={`/projects/${projectRow.id}`}
              className={({ isActive }) =>
                isActive ? activeClassName : undefined
              }
              style={{
                textDecoration: 'none',
                paddingLeft: '1.2em',
                paddingRight: '1.2em',
                paddingTop: '0.4em',
                paddingBottom: '0.4em',
              }}
            >
              {projectName}
            </NavLink>
            {publishProject && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <TextField
                  onFocus={(event) => {
                    event.target.select();
                  }}
                  sx={{ width: '100%',marginTop: '0.7em' }}
                  value={projectURL}
                  multiline
                />
                <ClickAwayListener onClickAway={handleCloseCopyTooltip}>
                  <Box>
                    <Tooltip
                      PopperProps={{
                        disablePortal: true,
                      }}
                      onClose={handleCloseCopyTooltip}
                      open={openCopyTooltip}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      title='Copied'
                    >
                      <IconButton onClick={handleCopyLinkToClipboard}>
                        <ContentCopyIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </ClickAwayListener>
              </Box>
            )}
          </Box>
        )}
      </TableCell>
      <TableCell>
        {editRow ? (
          <Box>
            <TextField
              label='Description'
              value={currentDescription}
              autoComplete='off'
              sx={{ width: '100%' }}
              onChange={handleCurrentDescriptionEdit}
              multiline
            />
          </Box>
        ) : (
          <>
            {description && description.length > 65 ? (
              <Tooltip title={description}>
                <Box>{description.slice(0, 100)}...</Box>
              </Tooltip>
            ) : (
              <Box>{description}</Box>
            )}
          </>
        )}
      </TableCell>
      <TableCell align='left'>
        <Typography>{readableDate(lastModified)}</Typography>
      </TableCell>
      <TableCell align='right'>
        {editRow ? (
          <Button
            color='success'
            variant='contained'
            onClick={confirmEditProject}
          >
            {<CheckIcon />}
          </Button>
        ) : (
          <Button onClick={handleOpenEditProjectDetails}>
            <EditIcon sx={{ marginLeft: '62%' }} />
          </Button>
        )}
      </TableCell>
      <TableCell align='right'>
        <>
          <Button color='error' onClick={handleOpenDeleteDialog}>
            <DeleteIcon sx={{ fontSize: '1.2em' }} />
          </Button>
          <DeleteDialog
            openDeleteDialog={openDeleteDialog}
            handleCloseDeleteDialog={() => setOpenDeleteDialog(false)}
            title={'Are you sure that you want to delete this project?'}
            content={dialongContent}
            handleAgreeDelete={handleDeleteProject}
          />
        </>
      </TableCell>
    </TableRow>
  );
};
export default ProjectRow;
