import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Amplify } from 'aws-amplify';
import { BrowserRouter as Router } from 'react-router-dom';
import config from './config';

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
    oauth: {
      domain: `${config.cognito.COGNITO_DOMAIN}.auth.${config.cognito.REGION}.amazoncognito.com`,
      scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn:
        config.stage.STAGE_NAME === 'prod'
          ? 'https://valuetree.spatineo.com/projects'
          : 'http://localhost:3000/projects',
      redirectSignOut:
        config.stage.STAGE_NAME === 'prod'
          ? 'https://valuetree.spatineo.com'
          : 'http://localhost:3000',
      responseType: 'token',
    },
  },
  API: {
    endpoints: [
      {
        name: 'projects',
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION,
      },
      {
        name: 'valuetrees',
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION,
      },
      {
        name: 'public',
        endpoint: config.publicApiGateway.URL,
        region: config.publicApiGateway.URL,
      },
    ],
  },
});

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>
);

reportWebVitals();
