import React, { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { ValuetreeMeta } from '../types';
import { Typography, Button, Drawer, Box } from '@mui/material/';
import { API, Auth } from 'aws-amplify';
import { TreeView, TreeItem } from '@mui/lab/';
import CreateValuetree from '../CreateValuetree/CreateValuetree';
import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useAppContext } from '../../lib/contextLib';

interface SideMenuDrawerProps {
  drawerWidth: number;
}

const SideMenuDrawer: React.FC<SideMenuDrawerProps> = ({ drawerWidth }) => {
  const [openCreateValuetree, setOpenCreateValuetree] =
    useState<boolean>(false);
  const [valuetreeName, setValuetreeName] = useState<string>('');
  const [description, setDescription] = useState<string>('');

  const {
    openDrawer,
    setOpenDrawer,
    selectedProjectValuetrees,
    setSelectedProject,
    setSelectedProjectValuetrees,
    setCurrentValuetreeNodes,
    setCurrentValuetreeLinks,
    copyFromValueTreeId,
    setCopyFromValueTreeId,
    selectedProject,
  } = useAppContext();

  const navigate = useNavigate();
  let { projectid, valuetreeid } = useParams();

  useEffect(() => {
    async function loadProjectValuetrees() {
      setOpenDrawer(true);
      const getProjectValuetrees =
        projectid && (await fetchProjectValuetrees(projectid));
      setSelectedProjectValuetrees(getProjectValuetrees);
    }

    loadProjectValuetrees();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectid]);

  const fetchProjectValuetrees = async (projectId: string) => {
    return API.get('valuetrees', `/valuetrees/project/${projectId}`, {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    });
  };

  const fetchCopyFromValuetree = async (id: string) => {
    return API.get('valuetrees', `/valuetrees/${id}/${projectid}`, {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    });
  };

  async function createNewValuetree() {
    if (!copyFromValueTreeId) {
      const newValueTree = {
        valueTreeName: valuetreeName,
        description: description,
        projectId: projectid,
        nodes: [],
        links: [],
        published: false,
      };
      return API.post('valuetrees', '/valuetrees', {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getAccessToken()
            .getJwtToken()}`,
        },
        body: newValueTree,
      });
    }

    const foundValuetree = await fetchCopyFromValuetree(copyFromValueTreeId);

    const newValuetree = {
      valueTreeName: valuetreeName,
      description: description,
      projectId: projectid,
      nodes: foundValuetree && foundValuetree.nodes,
      links: foundValuetree && foundValuetree.links,
      published: foundValuetree && foundValuetree.published,
    };
    return API.post('valuetrees', '/valuetrees', {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
      body: newValuetree,
    });
  }

  const handleSubmitNewValuetree = async () => {
    try {
      const newValuetree = await createNewValuetree();
      const newValuetreeMeta = {
        id: newValuetree.id,
        valueTreeName: newValuetree.valueTreeName,
      };
      setSelectedProjectValuetrees([
        ...selectedProjectValuetrees,
        newValuetreeMeta,
      ]);
    } catch (error) {
      console.error(error, 'Error Creating a new tree');
    }
    setValuetreeName('');
    setDescription('');
    setOpenCreateValuetree(false);
  };

  const handleBackToProjects = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setCurrentValuetreeNodes([]);
    setCurrentValuetreeLinks([]);
    setSelectedProject(null);
    navigate('/projects');
  };

  const handleOpenAddTree = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpenCreateValuetree(true);
  };

  const handleCloseCreateValuetreeDialog = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    setOpenCreateValuetree(false);
    setCopyFromValueTreeId('');
    setValuetreeName('');
  };

  const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValuetreeName(event.target.value);
  };

  const handleChangeDescription = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDescription(event.target.value);
  };

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
          zIndex: 10,
          boxShadow: '0 0 15px rgba(0,0,0,0.75)',
        },
      }}
      variant='persistent'
      anchor='left'
      open={openDrawer}
    >
      <Box sx={{ marginTop: '8vh' }} className='projects-sidebar-div'>
        {selectedProject ? (
          <Box>
            <Box>
              <Button
                sx={{
                  width: '80%',
                  padding: '10px',
                  margin: '10px 10px 30px',
                  backgroundColor: '#30558D !important'
                }}
                variant='contained'
                onClick={handleBackToProjects}
              >
                <ArrowBackIosNewIcon />
                Back to projects
              </Button>
            </Box>
            <Box>
              <Button
                sx={{
                  width: '60%',
                  padding: '10px',
                  margin: '0px 12px 30px',
                  backgroundColor: '#30558D !important'
                }}
                variant='contained'
                onClick={handleOpenAddTree}
              >
                <AddIcon /> Add tree
              </Button>
              <Box>
                {selectedProjectValuetrees && (
                  <CreateValuetree
                    valuetreeName={valuetreeName}
                    description={description}
                    openCreateValuetree={openCreateValuetree}
                    handleCloseCreateValuetreeDialog={
                      handleCloseCreateValuetreeDialog
                    }
                    handleSubmitNewValuetree={handleSubmitNewValuetree}
                    handleChangeName={handleChangeName}
                    handleChangeDescription={handleChangeDescription}
                  />
                )}
              </Box>
            </Box>
            <Box style={{ margin: '20px' }}>
              {selectedProject && <h2>{selectedProject.projectName}</h2>}
            </Box>
            <Box style={{ margin: '30px 0px' }}>
              <TreeView
                aria-label='file system navigator'
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpanded={['root']}
                defaultExpandIcon={<ChevronRightIcon />}
                sx={{
                  flexGrow: 1,
                  overflowY: 'auto',
                  paddingBottom: '150px',
                }}
              >
                <TreeItem nodeId={'root'} label={'Valuetrees'}>
                  {selectedProjectValuetrees ? (
                    selectedProjectValuetrees
                      .sort(
                        (
                          a: { valueTreeName: string },
                          b: { valueTreeName: string }
                        ) => a.valueTreeName.localeCompare(b.valueTreeName)
                      )
                      .map((valuetree: ValuetreeMeta) => {
                        return (
                          <Link
                            key={valuetree.id}
                            style={{
                              textDecoration: 'none',
                            }}
                            to={`/projects/${projectid}/${valuetree.id}`}
                          >
                            <Button
                              className='valueTreeButton'
                              sx={{
                                textAlign: 'left',
                                cursor: 'pointer',
                                width: '90%',
                                display: 'flex',
                                color: 'black',
                                borderRadius: '5px',
                                justifyContent: 'space-between',
                                padding: '10px 5px',
                                margin: '15px 0px',
                                backgroundColor:
                                  valuetreeid === valuetree.id
                                    ? 'lightblue'
                                    : '#F8F8F8',
                                border:
                                  valuetreeid === valuetree.id
                                    ? '1px solid gray'
                                    : '1px solid #EEEEEE',
                              }}
                            >
                              {valuetree.valueTreeName}
                            </Button>
                          </Link>
                        );
                      })
                  ) : (
                    <Typography
                      sx={{ color: 'lightgray', textAlign: 'center' }}
                    >
                      No valuetrees to show
                    </Typography>
                  )}
                </TreeItem>
              </TreeView>
            </Box>
          </Box>
        ) : (
          <Typography
            sx={{ marginTop: '20px', textAlign: 'center', fontWeight: 'bold' }}
          >
            Please select a project
          </Typography>
        )}
      </Box>
    </Drawer>
  );
};
export default SideMenuDrawer;
