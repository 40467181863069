import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import SpatineoLogo from '../../assets/Spatineo-logo.png';
import { useAppContext } from '../../lib/contextLib';
import { Auth } from 'aws-amplify';
import {
  Box,
  Typography,
  Toolbar,
  IconButton,
  Menu,
  Avatar,
  MenuItem,
  Button,
} from '@mui/material/';
import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

interface TopNavbarProps {
  AppBar: any;
}

const TopNavbar: React.FC<TopNavbarProps> = ({ AppBar }) => {
  const [username, setUsername] = useState<null | string>(null);
  const [userPicture, setUserPicture] = useState<undefined | string>(undefined);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const {
    userHasAuthenticated,
    openDrawer,
    user,
    setOpenDrawer,
    setCurrentValuetreeLinks,
    setCurrentValuetreeNodes,
    setSelectedProject,
  } = useAppContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      setUsername(
        `${user.attributes.given_name} ${user.attributes.family_name}`
      );
      setUserPicture(`${user.attributes.picture}`);
    }
  }, [user]);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = async (e: React.MouseEvent<HTMLElement>) => {
    userHasAuthenticated(false);
    await Auth.signOut();
  };

  const handleBackToProjects = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setCurrentValuetreeNodes([]);
    setCurrentValuetreeLinks([]);
    setSelectedProject(null);
    navigate('/projects');
  };

  const handleDrawerToggle = () => {
    setOpenDrawer(!openDrawer);
  };

  return (
    <AppBar position='fixed' sx={{ zIndex: 100, backgroundColor: '#30558D' }}>
      <Toolbar sx={{ width: '100%' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <IconButton
            onClick={handleDrawerToggle}
            size='large'
            edge='start'
            color='inherit'
            aria-label='open drawer'
          >
            {openDrawer ? <ChevronLeftIcon style={{marginLeft: '11rem'}} /> : <MenuIcon />}
          </IconButton>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              marginLeft: '20px',
              alignItems: 'center',
            }}
          >
            <Button onClick={handleBackToProjects}>
              <img
                style={{ height: '40px', width: '40px', marginLeft: openDrawer ? '-1rem' :'10rem' }}
                src={SpatineoLogo}
                alt='Spatineo logo'
              />
              <h2 style={{ color: 'white' }}>Spatineo Value Tree</h2>
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            marginLeft: 'auto',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <IconButton onClick={handleOpenUserMenu}>
            <Avatar alt='no picture' src={userPicture} />
          </IconButton>
          <Menu
            sx={{ mt: '45px' }}
            id='menu-appbar'
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            <MenuItem sx={{ width: '150px' }} disabled>
              <PersonIcon />
              <Typography sx={{ marginLeft: '10px' }}>{username}</Typography>
            </MenuItem>
            <MenuItem sx={{ width: '150px' }} onClick={handleLogout}>
              <LogoutIcon />
              <Typography sx={{ marginLeft: '10px' }}>Logout</Typography>
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};
export default TopNavbar;
