import React from 'react';
import { Link } from 'react-router-dom';
import { Box } from '@mui/material';

const NotFound = () => {
  return (
    <>
      <Box>Not Found</Box>
      <Box>
        You are not logged in? You need to be logged in{' '}
        <Link to='/'>Login</Link>
      </Box>
    </>
  );
};

export default NotFound;
