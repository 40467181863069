import React, { useState } from 'react';
import { Link } from '../types';
import { useAppContext } from '../../lib/contextLib';
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Button,
  Tooltip,
  Box,
  Snackbar,
} from '@mui/material';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import LinkRow from './LinkRow';
import './linksTab.css';
import CreateLink from './CreateLink';

const LinksTab: React.FC = () => {
  const [openCreateLink, setOpenCreateLink] = useState<boolean>(false);
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const [createLinkSuccess, setCreateLinkSuccess] = useState<boolean>(true);
  const [editLinkErrorMsg, setEditLinkErrorMsg] = useState<string>('');

  const { currentValuetreeLinks } = useAppContext();

  const handleShowCreateNewLink = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    setOpenCreateLink(!openCreateLink);
  };

  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref
  ) {
    return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
  });

  const handleCloseAlert = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlert(false);
  };

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: '20px 5px',
        }}
      >
        <Box sx={{ margin: '10px 10px 10px 0px' }}>
          {openCreateLink ? (
            <Tooltip title='Cancel'>
              <Button
                sx={{ width: '110px' }}
                variant='contained'
                color='warning'
                onClick={handleShowCreateNewLink}
              >
                {<CloseIcon />} Cancel
              </Button>
            </Tooltip>
          ) : (
            <Tooltip title='Add node'>
              <Button
                sx={{ width: '110px' }}
                variant='contained'
                color='success'
                onClick={handleShowCreateNewLink}
              >
                {<AddIcon />}
              </Button>
            </Tooltip>
          )}
        </Box>
        <CreateLink openCreateLink={openCreateLink} />
      </Box>
      <TableContainer component={Paper} sx={{ maxHeight: 440, width: '100%' }}>
        <Table stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: '20%' }}>Source</TableCell>
              <TableCell sx={{ width: '20%' }}>Target</TableCell>
              <TableCell sx={{ width: '7,5%' }}>Type</TableCell>
              <TableCell sx={{ width: '7,5%' }}>Weight</TableCell>
              <TableCell sx={{ width: '7,5%' }}>Color</TableCell>
              <TableCell sx={{ width: '7,5%' }}>Description</TableCell>
              <TableCell sx={{ width: '5%' }}>Url</TableCell>
              <TableCell sx={{ width: '5%' }}>&nbsp;</TableCell>
              <TableCell sx={{ width: '5%' }}>&nbsp;</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentValuetreeLinks.map((linkRow: Link) => {
              return (
                <LinkRow
                  key={linkRow.id}
                  linkRow={linkRow}
                  setOpenAlert={setOpenAlert}
                  setCreateLinkSuccess={setCreateLinkSuccess}
                  setEditLinkErrorMsg={setEditLinkErrorMsg}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box>
        <Snackbar
          open={openAlert}
          autoHideDuration={3000}
          onClose={handleCloseAlert}
        >
          {createLinkSuccess === true ? (
            <Alert
              onClose={handleCloseAlert}
              severity='success'
              sx={{ width: '100%' }}
            >
              Link has been updated!
            </Alert>
          ) : editLinkErrorMsg.length > 0 ? (
            <Alert
              onClose={handleCloseAlert}
              severity='error'
              sx={{ width: '100%' }}
            >
              {editLinkErrorMsg}
            </Alert>
          ) : (
            <Alert
              onClose={handleCloseAlert}
              severity='error'
              sx={{ width: '100%' }}
            >
              Please make sure all fields are filled correctly
            </Alert>
          )}
        </Snackbar>
      </Box>
    </Box>
  );
};
export default LinksTab;
