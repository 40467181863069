import React, { useState } from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import NodesTab from '../NodesTab/NodesTab';
import LinksTab from '../LinksTab/LinksTab';
import ExportTreeTab from '../ExportTreeTab/ExportTreeTab';
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

interface Props {
  printRef: any;
}

const TabsComponent: React.FC<Props> = ({ printRef }) => {
  const [tabValue, setTabValue] = useState<number>(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <Box>
      <Box>
        <Tabs value={tabValue} onChange={handleTabChange}>
          <Tab label='Nodes' {...a11yProps(0)} />
          <Tab label='Links' {...a11yProps(1)} />
          <Tab label='Tree' {...a11yProps(2)} />
        </Tabs>
      </Box>
      <TabPanel value={tabValue} index={0}>
        <NodesTab />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <LinksTab />
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <ExportTreeTab printRef={printRef} />
      </TabPanel>
    </Box>
  );
};
export default TabsComponent;
