import React, { useState, useMemo } from 'react';
import { API, Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Button,
  Box,
  Typography,
} from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import { useAppContext } from '../../lib/contextLib';
import CreateProject from '../../components/CreateProject/CreateProject';
import './projectPage.css';
import ProjectRow from './ProjectRow';
import { Project } from '../../components/types';

const ProjectsPage: React.FC = () => {
  const { setSelectedProjectValuetrees, setOpenDrawer, listOfProjects,setListOfProjects, setSelectedProject } = useAppContext();
  const [openNewProjectModal, setOpenNewProjectModal] = useState<boolean>(false);
  const [projectName, setProjectName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [sortByDate, setSortByDate] = useState<boolean>(false);

  const navigate = useNavigate();

  const handleChangeProjectName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setProjectName(event.target.value);
  };

  const handleChangeProjectDescription = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDescription(event.target.value);
  };

  const handleOpenProjectModal = (e: React.MouseEvent<Element, MouseEvent>) => {
    setOpenNewProjectModal(true);
  };

  const handleSubmitForm = async () => {
    try {
      const newProject = await createNewProject(projectName);
      setListOfProjects([...listOfProjects, newProject]);
      setSelectedProjectValuetrees([]);
      navigate('/projects');
      setSelectedProject(null)
      setOpenDrawer(true);
    } catch (error) {
      console.error(error);
    }
    setProjectName('');
    setDescription('');
    setOpenNewProjectModal(false);
  };

  async function createNewProject(project: string) {
    const newProjectObject = {
      projectName: project,
      description: description,
      published: false,
    };
    return API.post('projects', '/projects', {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
      body: newProjectObject,
    });
  }

  const handleCloseCreateNewProject = () => {
    setOpenNewProjectModal(false);
  };

  const projectListSortedByName  = useMemo(() => 
    [...listOfProjects].sort((a, b) => a.projectName.localeCompare(b.projectName)), [listOfProjects])
  const projectListSortedByDate  = useMemo(() => 
    [...listOfProjects].sort((a, b) => {
      const aDate = +new Date(a.lastModified)
      const bDate = +new Date(b.lastModified)
      return bDate - aDate;
    }), [listOfProjects])

  return (
    <Box className='project-page-container'>
      <TableContainer component={Paper}>
        <Box sx={{ backgroundColor: 'lightgray' }}>
          <Box
            sx={{
              fontWeight: 'bold',
              fontSize: 16,
              textTransform: 'uppercase',
              textAlign: 'center',
              color: 'rgb(0, 109, 200)',
            }}
          >
            <Button
              sx={{ width: '50%', margin: '12px 0' }}
              onClick={handleOpenProjectModal}
              color='primary'
              variant='outlined'
            >
              <AddIcon fontSize='large' />
              New Project
            </Button>
          </Box>
        </Box>
        <Table stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography sx={{ paddingLeft: '8px' }}>Name</Typography>
              </TableCell>
              <TableCell align='left'>
                <Typography>Description</Typography>
              </TableCell>
              <TableCell align='left'>
                <Button sx={{ color: 'black', textTransform: 'capitalize'}} onClick={() => setSortByDate(!sortByDate)}>
                  <Typography>Date </Typography>
                  {sortByDate ? <ExpandLessIcon sx={{marginLeft: '16%'}}/> : <ExpandMoreIcon  sx={{marginLeft: '16%'}}/>}
                </Button>
              </TableCell>
              <TableCell align='right'>
                <Typography>Edit</Typography>
              </TableCell>
              <TableCell align='right' sx={{ paddingRight: '32px' }}>
                <Typography>Delete</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {listOfProjects && sortByDate ? 
              projectListSortedByDate.map((projectRow: Project) => <ProjectRow key={projectRow.id} projectRow={projectRow} />)
              : projectListSortedByName.map((projectRow: Project) => <ProjectRow key={projectRow.id} projectRow={projectRow} /> )
              }
          </TableBody>
        </Table>
      </TableContainer>
      <CreateProject
        name={projectName}
        description={description}
        openNewProjectModal={openNewProjectModal}
        handleChangename={handleChangeProjectName}
        handleChangeDescription={handleChangeProjectDescription}
        handleCloseCreateNewProject={handleCloseCreateNewProject}
        handleSubmit={handleSubmitForm}
      />
    </Box>
  );
};
export default ProjectsPage;
