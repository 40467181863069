const config = {
  apiGateway: {
    REGION: process.env.REACT_APP_REGION,
    URL: process.env.REACT_APP_API_URL,
  },
  publicApiGateway: {
    URL: process.env.REACT_APP_PUBLIC_API_URL,
    REGION: process.env.REACT_APP_REGION,
  },
  cognito: {
    REGION: process.env.REACT_APP_REGION,
    USER_POOL_ID: process.env.REACT_APP_USER_POOL_ID,
    APP_CLIENT_ID: process.env.REACT_APP_USER_POOL_CLIENT_ID,
    IDENTITY_POOL_ID: process.env.REACT_APP_IDENTITY_POOL_ID,
    COGNITO_DOMAIN: process.env.REACT_APP_COGNITO_DOMAIN,
  },
  stage: {
    STAGE_NAME: process.env.REACT_APP_STAGE_NAME,
  },
};

export default config;
